@tailwind base;
@tailwind components;
@tailwind utilities;


.calc-button {
    @apply flex-auto dark:bg-gray-50 bg-black text-white dark:bg-opacity-10 bg-opacity-60 rounded-xl shadow-sm h-10 hover:shadow-md m-1 transition-colors font-mono outline-none;
}

.calc-button-lg {
    flex-grow: 2.3;
}

.op-btn {
    @apply dark:bg-yellow-500 bg-yellow-500 text-black dark:text-black hover:bg-opacity-75 text-xl;
}

.op-btn.active {
    @apply bg-yellow-100;
}

.num-btn {
    @apply focus:bg-gray-400 focus:outline-none focus:text-black border-0 hover:text-black hover:bg-gray-300;
}

.ctrl-btn {
    @apply bg-gray-400 dark:bg-gray-400 text-black hover:bg-gray-300 dark:hover:bg-gray-200;
}

.calc-link {
    @apply dark:text-white text-black transition-colors;
}